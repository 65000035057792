<template>
  <div class="col-12 card card-body">
    <div class="col-12 table-responsive">
        <div class="col-12 text-center g" v-if="loading">
            <img src="/files/loading.svg" alt="">
        </div>
        <div class="col-12 col-lg-4 g">
            <div class="card card-body">
                <div class="form-group">
                  <label for="">من انتهى اشتراكه منذ كام يوم؟</label>
                  <input type="text"
                    class="form-control" v-model="days">
                </div>
                <div class="form-group">
                  <label for="">هيخصم كام؟</label>
                  <input type="number"
                    class="form-control" v-model="amount">
                </div>
                <div class="form-group">
                  <label for="">الرسالة</label>
                  <textarea name="" v-model="message" class="form-control" id="" cols="30" rows="10"></textarea>
                </div>
                <button class="btn btn-primary" @click="create()">ارسال الان</button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            message: `{name}
{code}
{link}`,
            amount: 50,
            days: 30,
            loading: false
        }
    },
    created(){
    },
    methods: {
        create(){
            var g = this;
            this.loading = true;
            $.post(api + '/admin/send', {
                jwt: localStorage.getItem("jwt"),
                message: this.message,
                days: this.days,
                amount: this.amount
            })
            .then(function(response){
                g.loading = false
                if(response.status == 100){
                    alert("تم الارسال")
                }else{
                    g.messages = []
                    alert("حدث خطأ.")
                }
            })
            .fail(function(){
                g.loading = false
                g.messages = []
                alert("حدث خطأ")
            })
        }
    }
}
</script>

<style>

</style>